import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Modal from "react-modal";
import { navigate, graphql } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";
import JobsListing from "../components/jobs-listing";
import Hero from "../components/hero";
import CompanyDetail from "../components/company-detail";

export default ({ path, pageContext, data: { cms: { page_careers, seo } } }) => {
  const { data } = pageContext;
  const [modalOpen, setModalOpen] = useState(true);
  const [isLoadingJobs, setIsLoadingJobs] = useState(true);
  const [jobs, setJobs] = useState([]);

  const closeModal = () => {
    setModalOpen(false);
    navigate("/careers/companies/");

    const scrollPosition = JSON.parse(sessionStorage.getItem("scrollPosition"));
    const gatsbyContainer = document.querySelector("#___gatsby");
    gatsbyContainer.style.transform = "";

    setTimeout(() => {
      window.scrollTo(0, scrollPosition);
    }, 10);
  };

  const onModalOpen = () => {
    const scrollPosition = JSON.parse(sessionStorage.getItem("scrollPosition"));
    const gatsbyContainer = document.querySelector("#___gatsby");

    gatsbyContainer.style.transform = `translateY(${scrollPosition * -1}px)`;
  };

  // Fetch Jobs
  useEffect(() => {
    const url = `https://agreeable-forest-005accf10.azurestaticapps.net/jobs.json`;

    fetch(url)
      .then(function (response) {
        if (response.status >= 400) {
          throw new Error("Bad response from server");
        }

        return response.json();
      })
      .then(response => {
        const jobs = response.filter(job => job.company.name === data.name);
        setIsLoadingJobs(false);
        setJobs(jobs);
      })
      .catch(err => {
        console.log(err);
      });
  }, [data.name]);

  useEffect(() => {
    Modal.setAppElement("#main");
  }, []);

  return (
    <Layout>
      <SEO
        title={seo.og_title}
        description={seo.og_description}
        meta={[
          {
            "property": "og:image",
            "content": "/images/og-updated.png"
          }
        ]}
      />

      <Helmet>
        <script type="application/ld+json">
          {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "SoftBank Opportunity Fund",
          "url": "https://theopportunityfund.com",
          "logo": "https://theopportunityfund.com/images/og-updated.png",
          "alternateName": "The SoftBank Opportunity Fund",
          "sameAs": [
            "https://www.linkedin.com/company/sb-opportunity-fund"
          ]
        }
        `}
        </script>
      </Helmet>
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        onAfterOpen={onModalOpen}
        ariaHideApp={typeof window == "undefined"}
        className="modal"
        overlayClassName="overlay"
        bodyOpenClassName="modal-open"
      >
        <CompanyDetail
          name={data.name}
          slug={data.slug}
          logoUrl={data.logo_url}
          description={data.description}
          domain={data.domain}
          locations={data.locations}
          jobs={jobs}
          loading={isLoadingJobs}
          onCloseClick={closeModal}
        />
      </Modal>
      {typeof window !== "undefined" && (
        <>
          <Hero heading={page_careers.headline} body={page_careers.hero_body} stack_on_tablet={true}/>
          <JobsListing path={path} />
        </>
      )}
    </Layout>
  );
}
export const query = graphql`
query careers_company {
  cms {
    page_careers: entry(id: "7d121740-7519-49b8-b4b5-70c579b9c74c") {
      ... on cms_Entry_Pages_PageCareers {
        id
        title
        headline
        hero_body
      }
    }
    seo: SeoMeta(page_id: "7d121740-7519-49b8-b4b5-70c579b9c74c") {
      og_description
      og_title
      title
    }
  }
}
`